


















































































































































import { defineComponent, ref, computed, watch, onMounted, PropType, nextTick } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import ComboBoxInput from '@/elements/ComboBoxInput.vue'
import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import {
  decrementingDGStrategy,
  getEmptyDivisionGame,
  isEmptyDivisionGame,
} from '@/lib/support/models/DivisionGame/data'
import { DivisionGame } from '@/GeneratedTypes/DivisionGame'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import DateInput from '@/elements/DateInput.vue'
import { League } from '@/GeneratedTypes/League'
import dayjs from 'dayjs'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import {
  millisecondsToTimeString,
  timeStringLooksValid,
  timeStringSplit,
  timeStringToMilliseconds,
} from '@/lib/support/utilities/time/time'
import { AvailabilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/AvailabilityToScheduleBlock'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import TimePicker from '@/elements/TimePicker2.vue'
import { cloneDeep } from 'lodash'
import Loading from '@/elements/Loading.vue'
import { VolunteerRoleIDs } from '@/views/Programs/Volunteers/ext/roleutils'

import store from '@/store'

export default defineComponent({
  name: 'GameModal',
  components: { TimePicker, DateInput, SelectInput, Modal, Loading, ComboBoxInput },
  props: {
    divisionList: { type: Array as PropType<LeagueDivision[]>, default: () => [] },
    roundList: { type: Array as PropType<{ id: string; description: string }[]>, default: () => [] },
    game: {
      type: Object as PropType<DivisionGame>,
      default: () => getEmptyDivisionGame(decrementingDGStrategy(0)),
    },
    teamList: { type: Array as PropType<DivisionTeamInfo[]>, default: () => [] },
    facilityList: { type: Array as PropType<LeagueFacility[]>, default: () => [] },
    league: { type: Object as PropType<League>, default: () => getEmptyLeague() },
    programID: { type: String, default: () => '' },
    gameLength: { type: Number, default: 60 },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)

    const day = ref(new Date())
    const startTime = ref('8:00')
    const endTime = ref('9:00')
    const facilityID = ref(0)
    const awayTeamID = ref(0)
    const homeTeamID = ref(0)
    const divisionID = ref(0)
    const roundID = ref(0)

    const startTimeGuard = ref(true)
    const loading = ref(false)

    const homeTeam = computed({
      get(): number {
        if (homeTeamID.value == 0 && teams.value?.length) {
          homeTeamID.value = teams.value[0].teamID
        }
        return homeTeamID.value
      },
      set(i: number) {
        if (awayTeamID.value != i) {
          homeTeamID.value = i
        } else {
          // when the internal state is not mutated (skipped like here) it
          // will reflect the component's version of internal state even
          // if the prop was updated, so you have to force update the prop.
          const a = homeTeamID.value
          homeTeamID.value = 4.3e9 + Math.random()
          nextTick(() => {
            homeTeamID.value = a
          })
        }
      },
    })

    const awayTeam = computed({
      get(): number {
        if (awayTeamID.value == 0 && teams.value?.length > 1) {
          awayTeamID.value = teams.value[1].teamID
        }
        return awayTeamID.value
      },
      set(i: number) {
        if (homeTeamID.value != i) {
          awayTeamID.value = i
        } else {
          // when the internal state is not mutated (skipped like here) it
          // will reflect the component's version of internal state even
          // if the prop was updated, so you have to force update the prop.
          const a = awayTeamID.value
          awayTeamID.value = 4.3e9 + Math.random()
          nextTick(() => {
            awayTeamID.value = a
          })
        }
      },
    })

    const teams = computed(() => props.teamList.filter((x) => x.divisionID == divisionID.value))

    const currentFacility = computed((): LeagueFacility | undefined =>
      props.facilityList?.find((x) => x.facilityID == facilityID.value)
    )

    const rounds = computed(() => {
      const list = cloneDeep(props.roundList)
      return list.filter((r) => r.description != 'All')
    })

    function setTimeInAvailabilityWindow() {
      if (timeStringToMilliseconds(startTime.value) < timeStringToMilliseconds(minTime.value)) {
        startTime.value = minTime.value
      }
      if (timeStringToMilliseconds(endTime.value) < timeStringToMilliseconds(startTime.value)) {
        const gmLength = props.gameLength * 60000
        const gameTime =
          timeStringToMilliseconds(minTime.value) +
          ((props.game.gameEnd ?? new Date()).valueOf() - (props.game.gameStart ?? new Date()).valueOf())

        endTime.value = millisecondsToTimeString(gameTime + gmLength)
      }
    }

    function swap() {
      const home = homeTeam.value
      const away = awayTeam.value
      homeTeam.value = 1 // Circumvent check that stops the same team from being selected for home and away
      awayTeam.value = home
      homeTeam.value = away
    }

    watch(
      () => facilityID.value,
      () => {
        //resets the time availability on new facility change
        setTimeInAvailabilityWindow()
      }
      //{ immediate: true }  //for some reason this throws an error in the consle about the refs at the top being undefined, so making the call in onMounted
    )

    onMounted(() => {
      setTimeInAvailabilityWindow()
    })

    watch(
      () => startTime.value,
      () => {
        if (startTimeGuard.value) return // don't increment endTime if startTime changes because existing game populating
        const gmLength = props.gameLength * 60000
        nextTick(
          () =>
            (endTime.value = millisecondsToTimeString(timeStringToMilliseconds(startTime.value) + gmLength))
        )
      }
    )

    const availability = computed(() => {
      const facility = currentFacility.value
      if (facility) {
        const myDay = day.value?.getDay() ?? -1
        //** contains the schedule blocks that are for this facility
        return AvailabilityToScheduleBlocks(facility?.availability ?? [], FacilityEventEnum.GAMES)?.filter(
          (x) => x.day == myDay
        )
      }
      return []
    })

    const maxTime = computed(() => {
      if (availability.value?.length) {
        return millisecondsToTimeString(
          availability.value?.map((x) => x.timeEnd).reduce((x, y) => (x > y ? x : y))
        )
      }
      return '23:59:59'
    })

    const minTime = computed(() => {
      if (availability.value?.length) {
        return millisecondsToTimeString(
          availability.value?.map((x) => x.timeStart).reduce((x, y) => (x < y ? x : y))
        )
      }
      return '00:00:00'
    })

    const gameValue = computed(
      (): DivisionGame => {
        const start = timeStringSplit(startTime.value)
        const end = timeStringSplit(endTime.value)

        return {
          gameStart: dayjs(day.value).hour(start[0]).minute(start[1]).toDate(),
          gameEnd: dayjs(day.value).hour(end[0]).minute(end[1]).toDate(),
          divisionID: divisionID.value,
          roundNumber: roundID.value,
          homeTeamID: homeTeam.value,
          awayTeamID: awayTeam.value,
          facilityID: facilityID.value,
          gameID: props.game.gameID <= 0 ? 0 : props.game.gameID,
          leagueID: props.league.id,
          typeProgramID: props.programID,
          volunteers: refereeList.value
            .filter((r) => referees.value.some((x) => x == r.individualID))
            .map((x) => {
              return {
                volunteerIndividualID: x.individualID,
                roleID: VolunteerRoleIDs.REFEREES,
              }
            }),
        }
      }
    )

    /**************
    /* Referees 
    ***************/
    const refLabel = computed(() => store.getters.leagueAbstraction.refLabel)
    const refereeList = computed(() => store.getters.volunteers.referees)
    const referees = ref<number[]>([])

    const approvedRefs = computed(() => {
      const listCopy = cloneDeep(refereeList.value)
      return listCopy
        .filter(
          (r) =>
            r.refereeRoleApproved ||
            props.game.volunteers?.some((v) => v.volunteerIndividualID == r.individualID)
        )
        .sort((a, b) =>
          (a.formattedNameFirstLast ?? '').toLowerCase() > (b.formattedNameFirstLast ?? '').toLowerCase()
            ? 1
            : -1
        )
    })

    watch(
      () => props.league.upwardLeagueID,
      async () => {
        if (!props.league.upwardLeagueID) return
        loading.value = true
        await store.dispatch.volunteers.fetchReferees({
          upwId: props.league.upwardLeagueID ?? '',
          force: true,
        })
        loading.value = false
      },
      { immediate: true }
    )

    watch(
      () => props.game,
      () => {
        startTimeGuard.value = true
        defaultTimes()

        day.value = props.game.gameStart ?? new Date()
        startTime.value = dayjs(props.game.gameStart ?? '8:00').format('HH:mm')
        endTime.value = dayjs(props.game.gameEnd ?? '9:00').format('HH:mm')
        facilityID.value = props.game.facilityID
        awayTeamID.value = props.game.awayTeamID
        homeTeamID.value = props.game.homeTeamID
        roundID.value = props.game.roundNumber
        divisionID.value = props.game.divisionID
        referees.value = props.game.volunteers?.map((v) => v.volunteerIndividualID) ?? []

        setTimeInAvailabilityWindow()

        nextTick(() => (startTimeGuard.value = false))
      },
      { immediate: true }
    )

    function defaultTimes() {
      if (dayjs(props.game.gameStart ?? new Date()).format('HH:mm') == '00:00') {
        //For new games only
        props.game.gameStart = dayjs(props.game.gameStart ?? '8:00')
          .hour(8)
          .minute(0)
          .second(0)
          .toDate()
        props.game.gameEnd = dayjs(props.game.gameStart).add(90, 'minute').toDate()
      }
    }

    const shouldSubmitShow = computed(() => {
      const x =
        !!awayTeamID.value &&
        !!homeTeamID.value &&
        timeStringLooksValid(endTime.value) &&
        timeStringLooksValid(startTime.value) &&
        !!availability.value.length

      return x
    })

    /**
     * Caller will need to close the box.
     */
    function confirmed() {
      ctx.emit('confirmed', gameValue.value)
    }

    /**
     * Caller will need to close the box.
     */
    function deleted() {
      ctx.emit('deleted', gameValue.value)
    }

    function cancel() {
      closeModal()
    }

    return {
      isModalOpen,
      isEmptyDivisionGame,
      divisionID,
      roundID,
      rounds,
      homeTeam,
      teams,
      swap,
      awayTeam,
      facilityID,
      day,
      availability,
      startTime,
      minTime,
      maxTime,
      endTime,
      refLabel,
      approvedRefs,
      referees,
      gameValue,
      shouldSubmitShow,
      deleted,
      confirmed,
      cancel,
    }
  },
})
