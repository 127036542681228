


































import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify/types'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'
import { cloneDeep } from 'lodash'
import uuid from 'uuid'

export default defineComponent({
  name: 'DataTable',
  props: {
    value: {
      type: Array as PropType<any[] | null>,
      required: false,
      default: () => [],
    },
    headers: { type: Array as PropType<DataTableHeader[]>, required: true },
    items: { type: Array as PropType<any[]>, required: true },
    itemsPerPage: { type: Number, required: false, default: 5 },
    page: { type: Number, required: false, default: 1 },
    hideHeader: { type: Boolean, required: false, default: false },
    hideFooter: { type: Boolean, required: false, default: false },
    singleSelect: { type: Boolean, required: false, default: true },
    dense: { type: Boolean, required: false, default: false },
    sortBy: { type: String, required: false }, //column to sort by
    search: { type: String, required: false }, //value to search for
    itemKey: { type: String, required: false, default: '$internalid$' }, //value to search for
    showExpand: { type: Boolean, required: false, default: false },
    singleExpand: { type: Boolean, required: false, default: true },
    itemClass: { type: Function, required: false },
  },
  setup(props, ctx) {
    const lItems = ref<any[]>([])
    const allSelected = ref<any[]>([])
    function rowSelected(data: any, row: DataTableSelection<any>) {
      //set selected row
      row.select(row.isSelected ? false : true)
      row.isSelected = row.isSelected ? false : true

      //collect all selected
      const all = cloneDeep(allSelected.value)
      all.push(row.item)

      //emit
      ctx.emit('rowSelected', removeLocalId(row), all)
    }

    function dblClick(e: MouseEvent, row: DataTableSelection<any>) {
      ctx.emit('dblClick', removeLocalId(row))
    }

    function removeLocalId(row: DataTableSelection<any>) {
      const r = cloneDeep(row)
      delete r.item['$internalid$']
      return r
    }

    function pageCount(count: number) {
      ctx.emit('pageCount', count)
    }

    function currentData(data: any[]) {
      // currentData represents the current state of the data in the grid
      // emitting it here allows instantiating components to have access to the current
      // sort order, current page data, etc
      const local = cloneDeep(data)
      const localIdRemoved = local.map((d) => {
        delete d['$internalid$']
        return d
      })
      ctx.emit('currentData', localIdRemoved)
    }

    watch(
      () => props.items,
      () => {
        // dataTable requires a unique ID for each row.
        // Add a local one for cases where one is not explicity set by the instantiating component
        lItems.value = cloneDeep(props.items).map((i) => {
          return { ...i, $internalid$: uuid.v4() }
        })
      },
      { immediate: true }
    )

    watch(
      () => props.value,
      () => {
        allSelected.value = props.value ?? []
      },
      { immediate: true }
    )

    watch(
      () => allSelected.value,
      () => {
        ctx.emit('change', allSelected.value)
      },
      { immediate: true }
    )

    return { rowSelected, dblClick, lItems, allSelected, currentData, pageCount }
  },
})
