import restService from '@/services/restService'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { TypeProgramGameFormatInfo } from '@/GeneratedTypes/ListInfo/TypeProgramGameFormatInfo'
import { UpwardPracticePlanTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPracticePlanTypeID'
import { LeagueProgramGenderGradeInfo } from '@/GeneratedTypes/ListInfo/LeagueProgramGenderGradeInfo'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { LeagueDivisionExtendedInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionExtendedInfo'

const baseUrl = 'divisions/'

const retrieveByProgram = async (id: string, typeProgramId: string) => {
  const results = await restService.get<LeagueDivisionInfo[]>(`${baseUrl}${id}/${typeProgramId}`)

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrieveByProgram')
  }
}

const retrieveAll = async (id: string): Promise<LeagueDivisionInfo[] | null> => {
  const results = await restService.get<LeagueDivisionInfo[]>(`${baseUrl}${id}`)

  if (results.isSuccess && results.data) {
    return results.data
  }
  return null
}

const retrieveGradeGenderList = async (id: string, typeProgramId: string) => {
  const results = await restService.get<LeagueProgramGenderGradeInfo[]>(
    `${baseUrl}${id}/${typeProgramId}/gradegenderlist`
  )

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrieveGradeGenderList')
  }
}

const retrieveGameFormats = async (typeProgramId: string) => {
  const results = await restService.get<TypeProgramGameFormatInfo[]>(`${baseUrl}gameFormats/${typeProgramId}`)

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrieveGameFormats')
  }
}

const retrievePracticePlans = async (typeProgramId: string) => {
  const results = await restService.get<UpwardPracticePlanTypeID[]>(
    `${baseUrl}practicePlans/${typeProgramId}`
  )

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrievePracticePlans')
  }
}

const retrieveTemplate = async () => {
  const results = await restService.get<LeagueDivision>(`${baseUrl}new`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrieveTemplate')
  }
}

const retrievePreviousLeagueDivisions = async (id: string, account: string, typeProgramID: string) => {
  const results = await restService.get<LeagueDivisionInfo[]>(
    `${baseUrl}${id}/${typeProgramID}/previous/${account}`
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrievePreviousLeagueDivisions')
  }
}

const retrieveAllPreviousLeagueDivisions = async (id: string, account: string, programs: LeagueListItem) => {
  const cheerProgramId = programs.cheerProgramTypeID
  const sportProgramId = programs.programTypeID
  let sportDivisions!: LeagueDivisionInfo[] | null
  let cheerDivisions!: LeagueDivisionInfo[] | null

  if (sportProgramId) {
    sportDivisions = await retrievePreviousLeagueDivisions(id, account, sportProgramId)
  }
  if (cheerProgramId) {
    cheerDivisions = await retrievePreviousLeagueDivisions(id, account, cheerProgramId)
  }
  sportDivisions = sportDivisions || []
  cheerDivisions = cheerDivisions || []
  if (sportDivisions.length > 0 && cheerDivisions.length > 0) {
    return [...sportDivisions, ...cheerDivisions]
  } else if (sportDivisions.length > 0) {
    return sportDivisions
  } else if (cheerDivisions.length > 0) {
    return cheerDivisions
  } else {
    return []
  }
}

const validateNew = async (division: LeagueDivision) => {
  const results = await restService.post<UpwardVerificationDetails>(`${baseUrl}validate`, division)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.validateNew')
  }
}

const validateExisting = async (id: string, division: LeagueDivision) => {
  const results = await restService.post<UpwardVerificationDetails>(
    `${baseUrl}${id}/${division.typeProgramID}/${division.divisionID}/verificationDetails`,
    division
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.validateExisting')
  }
}

const update = async (id: string, division: LeagueDivision, removePracticesOnExcludedDates: boolean) => {
  const results = await restService.put<LeagueDivision>(
    `${baseUrl}${id}/${division.typeProgramID}/${division.divisionID}`,
    { ...division, removePracticesOnExcludedDates: removePracticesOnExcludedDates }
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.update')
  }
}

const importAllDivisions = async (
  toLeagueId: UpwardLeagueIDType,
  fromLeagueId: UpwardLeagueIDType | null,
  account: string
) => {
  const results = await restService.post<LeagueDivision>(
    `${baseUrl}${toLeagueId}/copyfrom?sourceUpwID=${fromLeagueId}&overwrite=true&accountNumber=${account}`,
    null
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.importAllDivisions')
  }
}

const create = async (division: LeagueDivision) => {
  const results = await restService.post<LeagueDivision>(`${baseUrl}`, division)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.create')
  }
}

const remove = async (id: string, typeProgramId: string, divisionId: number) => {
  const results = await restService.delete<LeagueDivision>(`${baseUrl}${id}/${typeProgramId}/${divisionId}`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.remove')
  }
}

const runPlayerDraft = async (
  id: UpwardLeagueIDType,
  typeProgramId: string,
  divisionId: number,
  draftingMode: string,
  practiceNightsFirst: boolean
) => {
  const results = await restService.post<number>(
    `${baseUrl}${id}/${typeProgramId}/${divisionId}/runPlayerDraft`,
    {
      draftingMode: draftingMode,
      practiceNightsFirst: practiceNightsFirst,
    }
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.runPlayerDraft')
  }
}

async function retrieveSummaryInfo(id: UpwardLeagueIDType, typeProgramId: string, divisionId: number) {
  const results = await restService.get<LeagueDivisionExtendedInfo>(
    `${baseUrl}${id}/${typeProgramId}/${divisionId}/extendedInfo`,
    {}
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in divisionsClient.retrieveSummaryInfo')
  }
}

export default {
  retrieveAll,
  retrieveByProgram,
  retrieveGameFormats,
  retrievePracticePlans,
  retrieveGradeGenderList,
  retrieveAllPreviousLeagueDivisions,
  retrieveTemplate,
  remove,
  validateExisting,
  validateNew,
  update,
  create,
  importAllDivisions,
  runPlayerDraft,
  retrieveSummaryInfo,
}
