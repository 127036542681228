import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'

export function getEmptyDivisionTeamInfo(): DivisionTeamInfo {
  return {
    leagueDirectorPartnerID: 0,
    teamID: 0,
    teamName: '',
    divisionID: 0,
    divisionName: '',
    leagueID: 0,
    typeColorID: null,
    coaches: [],
    practices: [],
    headCoachName: '',
    coachCount: 0,
    playerCount: 0,
    players: [],
    typeProgramID: '',
    upwardTeamID: '',
    teamPageUrl: '',
    currentPracticeNumber: 0,
    currentGameID: 0,
    currentGameOffenseFirst: false,
    overrideTypeGameFormatID: '',
    upwardLeagueID: '',
    hideTeamPageRoster: false,
    hideCoachEmailOnTeamPage: false,
    hideCoachPhoneOnTeamPage: false,
    hideAssistantCoachEmailOnTeamPage: true,
    hideAssistantCoachPhoneOnTeamPage: true,
    hideScheduleOnTeamPage: false,
    typePracticePlanID: '',
    typeGameFormatID: '',
    overrideNumberOfSegments: 0,
    ruleLevel: 0,
  }
}

export function getCoachNights(x: DivisionTeamInfo | undefined | null) {
  if (x && x.coaches?.length) {
    const headCoach = x.coaches.filter((c) => c.headCoach)
    const y = headCoach && headCoach.length > 0 ? headCoach[0] : x.coaches[0]
    if (y.practiceNights?.length) {
      return y.practiceNights.map((u) => u.typeDayID).join(', ')
    }
  }
  return 'n/a'
}

export function getTotalTeamHeight(team: DivisionTeamInfo) {
  return team.players?.map((x) => x.extraScore)?.reduce((total, current) => total + current, 0) ?? 0
}

export function getTotalTeamEvalScore(team: DivisionTeamInfo) {
  return team.players?.map((x) => x.evaluationScore)?.reduce((total, current) => total + current, 0) ?? 0
}

export function getTotalYearsExperience(team: DivisionTeamInfo) {
  return team.players?.map((x) => x.yearsExperience)?.reduce((total, current) => total + current, 0) ?? 0
}
