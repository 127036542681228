export const agreementText = `

<div id="text_62" class="form-html" data-component="text">
<p><strong>UPWARD UNLIMITED MINISTRY AGREEMENT</strong></p>
<p>
          Upward Unlimited (“Upward Unlimited”) programming develops youth sports ministry program materials (the “Upward program materials”) for sports ministry programs (the “Upward programs”) and licenses such materials to local churches, schools and other organizations for use in their youth ministry programs. By accepting this Agreement, the organization named below (the “Organization”) agrees to use the Upward program materials consistent with the principles expressed in this Agreement and in accordance with the standards set forth in the Policy Regarding the Christian Community, Mission and Standards of Upward Unlimited (the <strong><a href="https://www.upward.org/christian-community-policy" target="_blank" rel="noopener">“Christian Community Policy”</a></strong>).
        </p>
  <p>
  This Agreement covers all Upward programs and Upward program materials obtained by the Organization, unless it is superseded and replaced by a new Upward Unlimited Ministry Agreement.
  </p>
      <ol>
        <li>
          By accepting this Agreement, the Organization, through the authorized representative’s signature below, acknowledges that it has read and agrees with the Upward Unlimited Statement of Faith located at the end of this document. The Organization further acknowledges that it has read Upward’s Christian Community Policy and agrees to respect Upward’s other Christian beliefs, support Upward’s mission, and comply with applicable community standards.
        </li>
        <li>
          The term of this Agreement is one year from the date of signature on the contract, unless earlier terminated as provided herein. This Agreement will not automatically renew at the end of each year.
        </li>
        <li>
          The Organization agrees that it is solely responsible for its Upward Unlimited program(s) and any other program that uses Upward program materials. The Organization is therefore solely responsible for selecting and directing all trainings, games, and other activities, for selecting, maintenance and safe use of all equipment, vehicles, facilities, playing fields, and materials used in the Upward program(s), for selecting, training, and supervising all children and parents who participate in any Upward program, and for screening, selecting, training and supervising all volunteers, employees, vendors, and other personnel involved in and who plan, direct and carry out its Upward program(s). The Organization is solely responsible for the safety of all children and adults who are involved or participate in any way in its Upward program(s). Volunteers, employees, vendors and other personnel involved in the Organization’s Upward program(s) are not in any way volunteers, employees, vendors, or personnel of Upward, but are solely volunteers, employees, vendors, and personnel of the Organization. The Organization and Upward acknowledge and agree that:
          <br>
          <br>
          <ol class="subList">
            <li>
              their relative status is that of an independent purchaser and licensee (Organization) and a provider and licensor (Upward) of materials, programs and services;
            </li>
            <li>
              each of the Organization's local Upward programs is not a division, subsidiary, or partner (in the legal sense of that term) of Upward and personnel involved in the Organization's Upward program(s) are not volunteers, employees or staff of Upward;
            </li>
            <li>
              the Organization understands that the leadership training available through Upward is not all-inclusive and does not include training in hiring or other personnel practices and procedures;
            </li>
            <li>
              the Organization is solely responsible for maintaining a safe environment for children, including selecting, training and supervising leaders, coaches, vendors, volunteers and employees in child protection issues. The Organization seeks to provide a program that welcomes all children, within league age and gender parameters, regardless of religion, race, or economic status.
            </li>
          </ol>
        </li>
        <li>
          The Organization agrees that in using the Upward program materials and carrying out and promoting its Upward program(s) it shall comply with all applicable local, state and federal laws, and strongly suggest policies of insurance adequate to cover physical and other injuries to children and adults participating in or leading its Upward program(s) and other programs using Upward program materials.
        </li>
        <li>
          The Organization shall indemnify, defend and hold harmless Upward Unlimited and its successors and assigns, directors, officers, employees, volunteers, and other personnel and agents for all claims, damages and liability arising out of the Organization's Upward program(s) or otherwise out of its use of Upward program materials, including but not limited to claims against Upward and its employees and other personnel, and claims brought by or against the Organization, its members, employees, volunteers, and participants in its Upward program and other youth programs, whether or not members of the Organization. This provision survives the termination of this Agreement.
        </li>
        <li>
          Either party may terminate this Agreement at any time in its sole discretion without cause by giving written notice to the other party of such termination. Notice shall be given by mail via the U.S. Mail addressed to the party as provided herein or by delivery to the other party by another mail carrier.
        </li>
        <li>
          Upon acceptance of this Agreement by Upward Unlimited, Upward Unlimited agrees to provide the Organization with Upward program materials and offer training and support according to Upward program specifications, and the Organization shall thereafter be licensed to use Upward program materials, the Upward ministry approach and the Upward Property (as defined below) in accordance with and during the term of this Agreement.
        </li>
        <li>
          The Upward program materials, all copyrights, trademarks, trade names, service marks and similar proprietary rights in and to the Upward program(s) and Upward program materials, including without limitation the term “Upward” and all Upward logos (together referred to as the “Upward Unlimited Property”), are owned solely and exclusively by Upward Unlimited. The Organization may use the Upward Unlimited Property only in accordance with this Agreement and only in connection with the Organization’s Upward programs. The Organization agrees that its every use of Upward Unlimited Property, including derivative works, shall inure to the benefit of Upward Unlimited and that the Organization shall not at any time acquire any rights in such Upward Unlimited Property by virtue of any use it may make of such Upward Unlimited Property. The Organization further agrees that it will not at any time (i) apply for a registration of any trademark, service mark, business name, domain name or other designation which would be confusingly similar to, or would otherwise affect Upward Unlimited’s ownership of, the Upward Unlimited Property or (ii) file any document with any governmental authority to take any action which would adversely affect Upward Unlimited‘s ownership of the Upward Unlimited Property.
        </li>
        <li>
          The Organization acknowledges and consents that Upward may provide the Organization's name and contact address to certain value-added providers, who may contact the Organization regarding possible product sales. Upward receives no compensation from any such value-added providers. Further, UPWARD DOES NOT ENDORSE ANY SUCH VALUE- ADDED PROVIDER GENERALLY OR ANY OF ITS PRODUCTS SPECIFICALLY, AND UPWARD DISCLAIMS ALL WARRANTIES EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY FOR A PARTICULAR PURPOSE. The Organization acknowledges and consents that Upward may obtain access to personal information regarding program participants and their parents or guardians. The Organization agrees that Upward may use such personal information in a manner consistent with Upward's Conditions of Use and Privacy as amended from time to time. The current version of Upward's Conditions of Use and Privacy may be found at www.upward.org.
        </li>
        <li>
          This Agreement shall be governed by the laws of the State of South Carolina. The parties agree that, if they are unable to resolve a dispute or claim between themselves with respect to this Agreement, the parties shall seek to resolve their dispute by biblically-based mediation, before a mediator or conciliator selected and mutually agreed upon by the parties, prior to commencing any litigation.
        </li>
        <li>
          The individual signing this Agreement on behalf of the Organization certifies that he/she is authorized to sign this Agreement and that the governing body of the Organization has approved this Agreement
        </li>
      </ol>
    <p><strong>STATEMENT OF FAITH</strong></p>
    <ol>
    <li>We believe the Bible to be the inspired, authoritative, Word of God. </li>
    <li>We believe that there is one God, eternally existent in three persons - the Father, Son, and Holy Spirit. </li>
    <li> We believe in the deity of our Lord Jesus Christ, in His virgin birth, in His sinless life, in His miracles, in His vicarious and 
        atoning death through His shed blood, in His bodily resurrection, in His ascension to the right hand of the Father, and in 
        His personal return in power and glory. </li>
    <li>We believe that for the salvation of lost and sinful people, regeneration by the Holy Spirit is absolutely essential.</li>
    <li>We believe in the present ministry of the Holy Spirit, by whose indwelling the Christian is enabled to live a godly life. </li>
    <li>We believe in the resurrection of both the saved and the lost; they that are saved unto the resurrection of life, and they 
        that are lost unto the resurrection of eternal punishment. </li>
    <li>We believe in the spiritual unity of believers in our Lord Jesus Christ. </li>
    </ol>
    </div>

`
