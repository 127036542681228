import Vue from 'vue'
import { RootState } from '@/store/rootState'
import { ActionsImpl, createDirectStore } from 'direct-vuex'
import { orders } from '@/store/orders'

import { errors } from '@/store/errors'
import { authorization } from '@/store/authorization'
import { opportunityTypes } from '@/store/opportunityTypes'
import { leagueTypes } from '@/store/leagueTypes'
import { clinics } from '@/store/clinics'
import { connects } from '@/store/connects'
import { camps } from '@/store/camps'
import { communications } from '@/store/communications'
import { leagues } from '@/store/leagues'
import { productGroupTypes } from '@/store/productGroupTypes'
import { timezoneTypes } from '@/store/timezoneTypes'
import { phoneTypes } from '@/store/phoneTypes'
import { addressTypes } from '@/store/addressTypes'
import { promptToSave } from '@/store/promptToSave'
import { facilities } from '@/store/facilities'
import { partnerDayTypes } from '@/store/partnerDayTypes'
import { partnerPhoneTypes } from '@/store/partnerPhoneTypes'
import { partnerPreferredContactMethodTypes } from '@/store/partnerPreferredContactMethodTypes'
import { partnerPreferredTimeOfDayTypes } from '@/store/partnerPreferredTimeOfDayTypes'
import { partnerRoleTypes } from '@/store/partnerRoleTypes'
import { programTypes } from '@/store/programTypes'
import { participants } from '@/store/participants'
import { partners } from '@/store/partners'
import { volunteers } from '@/store/volunteers'
import { alerts } from '@/store/alerts'
import { sizeTypes } from '@/store/sizeTypes'
import { gradeTypes } from '@/store/gradeTypes'
import { teams } from '@/store/teams'
import { colorTypes } from '@/store/colorTypes'
import { returnReasonTypes } from '@/store/returnReasonTypes'
import { returnStatusTypes } from '@/store/returnStatusTypes'
import { productOfferingProductPackageTypes } from '@/store/productOfferingProductPackageTypes'
import { positionTypes } from '@/store/positionTypes'
import { paymentTypes } from '@/store/paymentTypes'
import { productFlow } from '@/store/ProductFlow'
import { creationParameters } from '@/store/creationParameters'
import { teamPageAlerts } from '@/store/teamPageAlerts'
import { gpOrderTypes } from '@/store/gpOrderTypes'
import { relationshipTypes } from '@/store/relationshipTypes'

import { divisions } from '@/store/divisions'
import { scheduling } from '@/store/scheduling'
import { partnerNearByAccounts } from '@/store/partnerNearByAccounts'
import { productOfferings } from '@/store/productOffering'
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'
import { leagueOrders } from '@/store/leagueOrder'
import { leagueAbstraction } from '@/store/leagueAbstraction'

Vue.use(Vuex)

export enum actionNames {
  startTimekeeping = 'startTimekeeping',
}

export enum getterNames {
  now = 'now',
  userLastSeen = 'userLastSeen',
  userInactiveMS = 'userInactiveMS',
}
const actions: ActionTree<RootState, RootState> & ActionsImpl = {
  [actionNames.startTimekeeping]: ({ commit }, payload?: boolean) => {
    if (!payload) setInterval(() => commit('updateTime'), 1000 * 30)
  },
}

export enum mutationNames {
  updateTime = 'updateTime',
  updateUserLastSeen = 'updateUserLastSeen',
}

const initialState: RootState = {
  now: new Date(),
  userLastSeen: new Date(),
}

const mutations: MutationTree<RootState> = {
  [mutationNames.updateTime]: (state) => {
    state.now = new Date()
  },
  [mutationNames.updateUserLastSeen]: (state) => {
    state.userLastSeen = new Date()
  },
}
const getters: GetterTree<RootState, RootState> = {
  [getterNames.now]: (state) => state.now,
  [getterNames.userLastSeen]: (state) => state.userLastSeen,
  [getterNames.userInactiveMS]: (state) => state.now.getTime() - state.userLastSeen.getTime(),
}

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
} = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: initialState,
  getters,
  actions,
  mutations,
  modules: {
    productFlow,
    productOfferings,
    creationParameters,
    camps,
    connects,
    clinics,
    leagues,
    leagueAbstraction,
    authorization,
    errors,
    participants,
    partners,
    volunteers,
    orders,
    leagueOrders,
    divisions,
    scheduling,
    opportunityTypes,
    leagueTypes,
    communications,
    productGroupTypes,
    timezoneTypes,
    phoneTypes,
    addressTypes,
    programTypes,
    promptToSave,
    facilities,
    partnerDayTypes,
    partnerPhoneTypes,
    partnerPreferredContactMethodTypes,
    partnerPreferredTimeOfDayTypes,
    partnerRoleTypes,
    partnerNearByAccounts,
    alerts,
    sizeTypes,
    gradeTypes,
    teams,
    colorTypes,
    returnReasonTypes,
    returnStatusTypes,
    productOfferingProductPackageTypes,
    positionTypes,
    paymentTypes,
    teamPageAlerts,
    gpOrderTypes,
    relationshipTypes,
  },
})

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext }

// export the typed store.
export default store

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: AppStore
  }
}
