









import { defineComponent, computed, onMounted, onUnmounted } from '@vue/composition-api'

import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'

import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

import store from '@/store'

export default defineComponent({
  name: 'DivisionsAndTeams',
  components: {
    VerticalTabs,
    Loading,
    Modal,
  },
  setup(props, ctx) {
    const id = computed(() => ctx.root.$route.params.id)
    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    onMounted(() => {
      store.dispatch.positionTypes.fetchAll(false) //get these cached up
    })

    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )

    const baseRoute = computed(() => {
      if (ctx.root.$route.fullPath.indexOf('/camp/') > 0) {
        return `/programs/camp/${routeId.value}divisions_and_teams`
      }
      return `/programs/league/${routeId.value}divisions_and_teams`
    })

    const tabs = computed(() => [
      { text: 'Divisions', to: `${baseRoute.value}/divisions` },

      {
        text: 'Team Setup',
        tabs: [
          { text: 'Create Teams', to: `${baseRoute.value}/teams` },
          { text: 'Assign Coaches', to: `${baseRoute.value}/coaches` },
          ...(isLeague.value ? [{ text: 'Assign Practices', to: `${baseRoute.value}/practices` }] : []),
        ],
      },

      { text: 'Team Management', to: `${baseRoute.value}/team-management` },
    ])

    onUnmounted(() => {
      store.commit.divisions.clearSelectedDivision(null)
    })

    return {
      tabs,
    }
  },
})
