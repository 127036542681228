



















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import { League } from '@/GeneratedTypes/League'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import { namespace } from 'vuex-class'
import { getterNames as leagueGetters, namespace as leagueStoreName } from '@/store/leagues'
import { getterNames as volunteerGetters, namespace as volunteerStoreName } from '@/store/volunteers'
import { getterNames as schedulingGetters, namespace as schedulingStoreName } from '@/store/scheduling'
import {
  actionNames as facilitiesActions,
  getterNames as facilitiesGetters,
  namespace as facilitiesStoreName,
} from '@/store/facilities'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import {
  actionNames as divisionActions,
  getterNames as divisionGetters,
  namespace as divisionNamespace,
} from '@/store/divisions'

import HorizontalTabs, { TabType } from '@/components/HorizontalTabs.vue'
import HorizontalTabFilter, { FilterTabType } from '@/components/HorizontalTabFilter.vue'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import DivisionSelect from '@/components/DivisionSelect.vue'
import SelectInput from '@/elements/SelectInput.vue'
import dayjs from 'dayjs'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { cloneDeep } from 'lodash'
import { isNullDate, toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { DivisionGameInfoToDivisionGame } from '@/lib/support/models/DivisionGame/DivisionGameInfoToDivisionGame'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import GameScheduleInfo from '@/views/Programs/Scheduling/Games/vues/GameScheduleInfo.vue'
import { DivisionGame } from '@/GeneratedTypes/DivisionGame'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { ScheduleGridData, scheduleSort } from '@/views/Programs/Scheduling/ext/helper'
import { decrementingDGStrategy, getEmptyDivisionGame } from '@/lib/support/models/DivisionGame/data'
import GameModal from '@/views/Programs/Scheduling/Games/ext/GameModal.vue'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import { AvailabilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/AvailabilityToScheduleBlock'
import ToggleLinks from '@/elements/ToggleLinks.vue'
import { FacilityEventInfo } from '@/GeneratedTypes/ListInfo/FacilityEventInfo'
import { eventid2id, id2eventid } from '@/views/Programs/Scheduling/ext/id_utils'
import store from '@/store'
import Loading from '@/elements/Loading.vue'
import { DeleteIcon } from '@/elements/Icons'
import { CalendarWeek } from '@/models/Calendar/CalendarWeek'

import Calendar from '@/elements/Calendar.vue'
import ScheduleSquadItem from '@/views/Programs/Scheduling/Squads/ScheduleDragItem.vue'
import { CalendarData } from '@/elements/Calendar/types'

import DataTable from '@/elements/DataTable/DataTable.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'
import { headers } from '@/views/Programs/Scheduling/Games/ext/headers'
import { maxStringLength } from '@/filters/maxStringLength'
import * as colors from '@/views/Programs/Scheduling/ext/colors'
import { CoachConflictTypeEnum } from '@/models/TeamManagement/CoachConflictTypeEnum'

const league = namespace(leagueStoreName)
const facilities = namespace(facilitiesStoreName)
const divisions = namespace(divisionNamespace)
const schedules = namespace(schedulingStoreName)
const volunteers = namespace(volunteerStoreName)

const WEEK_IN_SECONDS = 7 * 24 * 3600 * 1000
const START_DAY = 1 //monday

@Component({
  components: {
    DataTable,
    EditBtn,
    DeleteBtn,
    ToggleLinks,
    ConfirmationModal,
    Alert,
    SelectInput,
    DivisionSelect,
    HorizontalTabs,
    HorizontalTabFilter,
    TypeProgramTab,
    GameModal,
    Loading,
    DeleteIcon,
    GameScheduleInfo,
    Calendar,
    ScheduleSquadItem,
  },
  data() {
    return { headers }
  },
  methods: {
    maxStringLength,
  },
})
export default class GameSchedule extends Vue {
  /** getters */
  @league.Getter(leagueGetters.currentItem) league!: League
  @volunteers.Getter(volunteerGetters.referees) referees!: LeagueVolunteerInfo[]
  @facilities.Getter(facilitiesGetters.items) facilities!: LeagueFacility[]
  @schedules.Getter(schedulingGetters.gamesStatusText) schedulingStatus!: string
  @divisions.Getter(divisionGetters.allItems) divisionList!: (s: string) => LeagueDivisionInfo[]
  @divisions.Action(divisionActions.fetchAll) fetchAllDivisions!: ({
    upwardLeagueId,
    force,
  }: {
    upwardLeagueId: UpwardLeagueIDType
    force: boolean
  }) => Promise<boolean>
  @facilities.Action(facilitiesActions.load)
  private readonly retrieveFacilities!: ({ id }: { id: string }) => LeagueFacility[] | null

  //*** represents time-zone adjusted start and end times.
  private internalGames: DivisionGameInfo[] = []

  private readonly loadGames = this.store.dispatch.scheduling.loadGames
  private readonly clearGames = this.store.dispatch.scheduling.clearGames
  private readonly removeGame = this.store.dispatch.scheduling.removeGame
  private readonly pairTeams = this.store.dispatch.scheduling.pairTeams
  private readonly upsertGame = this.store.dispatch.scheduling.upsertGame
  private readonly loadTeams = this.store.dispatch.scheduling.loadTeams
  private readonly retrieveEvents = this.store.dispatch.scheduling.loadEvents

  get games() {
    return store.getters.scheduling.games
  }
  get events() {
    return store.getters.scheduling.events
  }
  get teams() {
    return this.$store.direct.getters.scheduling.teams
  }

  get coachConflictTypeEnum(): typeof CoachConflictTypeEnum {
    return CoachConflictTypeEnum
  }

  get autoScheduleRoute() {
    if (this.$route.params.divisionID) {
      return '../auto/' + this.divisionID
    }
    if (this.divisionID) {
      return 'auto/' + this.divisionID
    }
    return 'auto'
  }

  // TO DO: set back to list'
  private viewtype = 'list' //alternate is calendar

  private loading = true
  private readonly alertType = AlertTypeEnum.NOTICE
  private currentSelectedFacility = ''

  divisionID = parseInt(this.$route.params.divisionID) ?? 0

  get store() {
    return store
  }

  get gameDivisionList() {
    return (store.getters.divisions['allItems'](this.leagueID) as LeagueDivisionInfo[]).filter(
      (x) => x.typeProgramID == this.programID
    )
  }

  get divisionGameCount() {
    return this.gridSchedule.length
  }

  private autoscheduleWarning = false
  autoSchedule() {
    if (this.divisionGameCount > 0) {
      this.autoscheduleWarning = true
    } else {
      this.$router.push(this.autoScheduleRoute)
    }
  }

  autoscheduleConfirmed(response: boolean) {
    this.autoscheduleWarning = false
    if (response) this.$router.push(this.autoScheduleRoute)
  }

  private clearGameWarning = false
  async clearGameConfirmed(response: boolean) {
    this.clearGameWarning = false
    if (response) {
      const games = cloneDeep(this.games)
      const gameIds = games.filter((g) => g.divisionID == this.divisionID).map((g) => g.gameID)
      if (gameIds.length) {
        await this.clearGames({ id: this.leagueID, gameIDs: gameIds })
      }
    }
  }

  dblClickGrid(e: DataTableSelection<ScheduleGridData>) {
    const d = e.item
    this.eventClick({
      name: '',
      start: '',
      end: '',
      class: '',
      id: `GAMES${d.id}`,
      color: '',
      textColor: '',
      division: '',
    })
  }

  editItem(e: ScheduleGridData) {
    this.eventClick({
      name: '',
      start: '',
      end: '',
      class: '',
      id: `GAMES${e.id}`,
      color: '',
      textColor: '',
      division: '',
    })
  }

  clearDivisionGames() {
    this.clearGameWarning = true
  }

  private async refreshEvents() {
    await this.retrieveEvents({ id: this.leagueID })
  }

  @Watch('gameDivisionList', { immediate: true })
  gameDivisionListUpdated() {
    if (this.gameDivisionList.length && !this.divisionID) {
      this.divisionID = this.gameDivisionList[0].divisionID
    }
  }

  get division() {
    return this.gameDivisionList.find((x) => x.divisionID == this.divisionID) ?? getEmptyLeagueDivisionInfo()
  }

  get divisionName() {
    return this.division.divisionName
  }

  private currentGame: DivisionGame = getEmptyDivisionGame(decrementingDGStrategy(0))

  eventClick(e: CalendarData) {
    const id = eventid2id(this.event, e.id) || '0'
    const game = this.internalGames.find((x) => x.gameID == parseInt(id))
    if (game) {
      // game is going to be in league time zone, dialog expects it in local.
      this.currentGame = {
        ...DivisionGameInfoToDivisionGame(game),
        gameStart: game.gameStart,
        gameEnd: game.gameEnd,
        volunteers: game.volunteers,
      }
      this.gameModalShowing = true
    }
  }

  gameModalShowing = false

  /**
   * Add game button was clicked
   */
  clickAddGame() {
    const localGame = cloneDeep(this.makeNewGame())
    localGame.gameEnd = dayjs(localGame.gameStart ?? new Date())
      .add(this.division.gameLength, 'm')
      .toDate()
    this.currentGame = localGame
    this.gameModalShowing = true
  }

  showConfirmPairings = false
  showPairingsConfirmed = false
  clickPairGames() {
    this.showConfirmPairings = true
  }

  pairingsConfirmed(b: boolean) {
    this.showConfirmPairings = false

    if (b) {
      this.pairTeams({
        id: this.leagueID,
        divisionID: this.divisionID,
        programID: this.programID,
      })
      this.showPairingsConfirmed = true
    }
  }

  /***
   * Gives us the times the facilty is open for a given event
   */
  get weekdays(): number[] {
    const facility = this.facilities.find((x) => x.facilityID == this.currentFacilityID)

    if (facility) {
      return AvailabilityToScheduleBlocks(facility.availability ?? [], FacilityEventEnum.GAMES).map(
        (f) => f.day
      )
    }
    return [0, 1, 2, 3, 4, 5, 6]
  }

  /**
   * Returns a "game round" list for the drop down.
   * Apparently 1..n+1
   */
  get roundList() {
    const roundlimit = this.division.maxRoundNumber || 16
    const a = Array.from(Array(roundlimit), (x, i) => ({ id: i + 1, description: `Round ${i + 1}` }))
    if (this.viewtype != 'calendar') {
      a.unshift({ id: 0, description: 'All' })
    }
    return a
  }

  private round = 0

  /** ID of currently selected facilty **/
  get currentFacilityID() {
    return parseInt(this.currentSelectedFacility)
  }
  get currentFacility() {
    return this.currentSelectedFacility
  }
  set currentFacility(facility: string) {
    this.currentSelectedFacility = facility
  }

  /***
   * Used to constrain the calendar
   *
   */
  get startEpoch(): number {
    const gamedate = this.league.firstGameDate ?? new Date()
    return dayjs(gamedate).day(START_DAY).hour(0).minute(0).millisecond(0).valueOf() //get epoch of sunday before game day
  }

  /***
   * This logic will change per Thomas
   */
  get endEpoch(): number {
    // 16 WEEKS LATER
    return this.startEpoch + WEEK_IN_SECONDS * 16
  }

  /***
   * Build a new game on add game click
   */
  makeNewGame(): DivisionGame {
    const defaultStart = dayjs(this.startEpoch).hour(8)
    return {
      ...getEmptyDivisionGame(decrementingDGStrategy(parseInt(this.leagueID))),
      typeProgramID: this.programID,
      facilityID: this.currentFacilityID,
      divisionID: this.divisionID,
      gameStart: defaultStart.toDate(),
      gameEnd: defaultStart.add(this.division.gameLength, 'm').toDate(),
      roundNumber: this.round,
    }
  }

  @Watch('games', { immediate: true })
  updateInternalGames() {
    this.internalGames = cloneDeep(
      this.games.map((x) => ({
        ...x,
        gameStart: x.gameStart ? dayjs(x.gameStart).toDate() : null,
        gameEnd: x.gameEnd ? dayjs(x.gameEnd).toDate() : null,
      }))
    )
  }

  @Watch('division')
  divisionUpdated() {
    this.refreshTeams()
  }

  /** -- these computed properties will set what games are visible in the UI **/

  /**
   * does the given game meet round criteria
   */
  private meetsRoundCriteria(game: DivisionGameInfo | FacilityEventInfo) {
    return game?.roundNumber == this.round
  }

  /**
   * does the given game meet facility criteria
   */
  private meetsFacilityCriteria(game: DivisionGameInfo | FacilityEventInfo) {
    return game?.facilityID == this.currentFacilityID
  }

  /**
   * does the given game meet division criteria?
   * */
  private meetsDivisionCriteria(game: DivisionGameInfo | FacilityEventInfo) {
    return game.divisionID == this.divisionID
  }

  /**
   * Returns all games that are scheduled for the active facility, grey if not meeting
   * round and division criteria.
   */
  private get schedule(): CalendarData[] {
    return this.events
      .filter((x) => x.eventStart && x.eventEnd && this.meetsFacilityCriteria(x))
      .map((x) => {
        return {
          start: dayjs(x.eventStart ?? '').format('YYYY-MM-DDTHH:mm'),
          end: dayjs(x.eventEnd ?? '').format('YYYY-MM-DDTHH:mm'),
          name: `${x.eventName}`,
          class: this.meetsDivisionCriteria(x) ? 'active' : 'inactive',
          id: id2eventid(x.typeFacilityEventID ?? '', x.eventID.toString()),
          color: this.color(x, 'COLOR'),
          textColor: this.color(x, 'TEXT'),
          division: x.divisionName,
        } as CalendarData
      })
  }

  private color(e: FacilityEventInfo, type: 'COLOR' | 'TEXT') {
    if (
      e.typeFacilityEventID == FacilityEventEnum.SQUADS ||
      e.typeFacilityEventID == FacilityEventEnum.PRACTICES
    ) {
      return type == 'COLOR' ? colors.inactiveBgColor : colors.inactiveTextColor
    } else if (e.typeFacilityEventID == FacilityEventEnum.GAMES) {
      if (this.meetsDivisionCriteria(e)) {
        return type == 'COLOR' ? colors.eventBgColor : colors.eventTextColor
      } else {
        return type == 'COLOR' ? colors.eventInactiveBgColor : colors.eventInactiveTextColor
      }
    }
  }

  get totalUnscheduled() {
    return this.internalGames.filter((x) => isNullDate(toDate(x.gameStart))).length
  }
  /**
   * Returns all games for the list
   * round and division criteria.
   */
  private get gridSchedule(): ScheduleGridData[] {
    return this.internalGames
      .filter(
        (x) =>
          !isNullDate(toDate(x.gameStart)) &&
          x.divisionID === this.divisionID &&
          (x.roundNumber == this.round || this.round == 0)
      )

      .map((x) => ({
        start: x.gameStart ?? new Date(),
        end: x.gameEnd ?? new Date(),
        prettyDate: dayjs(x.gameStart ?? '').format('ddd MMM DD'),
        prettyTime: dayjs(x.gameStart ?? '').format('h:mmA') + ' - ' + dayjs(x.gameEnd ?? '').format('h:mmA'),
        title: `${x.awayTeamName} at ${x.homeTeamName}`,
        id: x.gameID,
        location: x.facilityName
          ? x.facilityName
          : this.facilities.find((y) => y.facilityID == x.facilityID)?.facilityName ?? 'n/a',
        division: x.divisionName
          ? x.divisionName
          : this.gameDivisionList.find((y) => y.divisionID == x.divisionID)?.divisionName ?? 'n/a',
        round: x.roundNumber,
        volunteers: this.referees
          .filter((r) => x.volunteers?.some((v) => v.volunteerIndividualID == r.individualID))
          .map((x: LeagueVolunteerInfo) => x.formattedNameFirstLast ?? '')
          .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
      }))
      .sort(scheduleSort)
  }

  @Watch('viewtype')
  viewTypeChanged() {
    if (this.viewtype == 'calendar' && this.round == 0) this.round = 1
    if (this.viewtype == 'list') this.round = 0
  }

  idTODelete = 0
  showDeleteConfirmed = false
  showDeleteConfirmation = false

  /***
   * This is called when delete is selected off the grid
   */
  deleteItem(r: ScheduleGridData) {
    this.idTODelete = r.id
    this.showDeleteConfirmation = true
  }

  async deleteConfirmed(b: boolean) {
    this.showDeleteConfirmation = false
    if (b) {
      await this.removeGame({ id: this.leagueID, gameID: this.idTODelete })
      this.showDeleteConfirmed = true
    }
  }

  /**
   * Called by delete on modal */
  async deleteGame(newelement: DivisionGame) {
    await this.removeGame({ id: this.leagueID, gameID: newelement.gameID })
    this.showDeleteConfirmed = true
    this.gameModalShowing = false
  }

  /** Called by the draggable game tiles */
  private async deleteUnscheduledGame(gameID: number) {
    await this.removeGame({ id: this.leagueID, gameID })
  }

  /***
   * Unscheduled data is data with a null time < NULL_DATE
   * also no facility, facility is assigned by dragging to the schedule.
   * map to the scheduler data type.
   */
  get unscheduledData() {
    return this.internalGames
      .filter(
        (x) => isNullDate(toDate(x.gameStart)) && this.meetsRoundCriteria(x) && this.meetsDivisionCriteria(x)
      )
      .map((g) => {
        return {
          name: `${g.awayTeamName} at ${g.homeTeamName}`,
          start: null,
          end: null,
          class: '',
          id: g.gameID,
        }
      })
  }

  eventDropped = (ev: CalendarData) => {
    const newevent = cloneDeep(ev)
    newevent.end = dayjs(newevent.start).add(this.division.gameLength, 'm').format('YYYY-MM-DDTHH:mm')
    return this.move(newevent as CalendarData)
  }

  /***
   * Receives a moved element on the calendar, time is correct when we get it on move.
   */
  eventMoved = async (ev: CalendarData) => {
    const event = cloneDeep(ev)
    event.id = eventid2id(this.event, ev.id)
    await this.move(event)
  }

  /**
   * Used after a modal is confirmed
   */
  async confirmGame(newelement: DivisionGameInfo) {
    try {
      await this.upsertGame({ id: this.leagueID, game: newelement })
    } catch (e) {
      throw e
    }

    this.gameModalShowing = false
  }

  /**
   * Called when a game time is moved (even from no time to time)
   * Adjust time based on source of data, game time is moved.
   */
  async move(ev: CalendarData) {
    try {
      const idToFind = parseInt(ev.id)
      const elementIdx = this.internalGames.findIndex((x) => x.gameID == idToFind)

      if (elementIdx >= 0) {
        const newelement = cloneDeep(this.internalGames[elementIdx])
        const start = dayjs(ev.start)
        newelement.gameStart = start.toDate()

        const end = dayjs(ev.end)
        const currentLengthMs = end.toDate().getTime() - start.toDate().getTime()
        var lengthMinutes = currentLengthMs > 0 ? currentLengthMs / 1000 / 60 : this.division.gameLength
        const endDate = start.add(lengthMinutes, 'minute').toDate()

        newelement.gameEnd = endDate
        newelement.facilityID = this.currentFacilityID
        newelement.divisionName = ev.division ?? ''

        await this.upsertGame({ id: this.leagueID, game: newelement })

        this.internalGames.splice(elementIdx, 1, newelement)
      }
    } catch (e) {
      throw e
    }
  }

  event = FacilityEventEnum.GAMES

  /***
   * Restricts tabs to the facilities that have programming for the given event
   */
  get facilitiesAsTabs(): TabType[] {
    return (
      this.facilitiesToFilter
        .filter((y) => y.selected)
        .map((x) => ({
          id: x.id,
          description: x.description,
        })) ?? []
    )
  }

  private facilitiesToFilter: FilterTabType[] = []
  private eventFacilities: LeagueFacility[] = []

  @Watch('facilities', { immediate: true })
  facilitiesUpdated() {
    this.facilitiesToFilter =
      this.facilities
        .filter((y) => (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0)
        .map((x) => ({
          id: x.facilityID.toString(),
          description: x.facilityName,
          selected: true,
        })) ?? []
    this.eventFacilities = this.facilities.filter(
      (y) => (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0
    )
  }

  facilityFilterUpdated(selectedFilters: string[]) {
    this.facilitiesToFilter.forEach((x) => {
      x.selected = selectedFilters.includes(x.id)
    })
  }

  async refreshGames() {
    await this.loadGames({ id: this.leagueID })
  }

  async refreshLeagues() {
    await this.fetchAllDivisions({ upwardLeagueId: this.leagueID, force: true })
  }

  get divisionTeams() {
    return this.teams.filter((x) => x.divisionID == this.divisionID)
  }

  /**
   * Get teams
   */
  private async refreshTeams() {
    if (this.programID) {
      await this.loadTeams({
        id: this.leagueID,

        programID: this.programID,
      })
    }
  }

  /**
   * Get teams
   */
  private async refreshFacilities() {
    await this.retrieveFacilities({ id: this.leagueID })
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */

  get programID() {
    if (this.league && this.league.programs?.length) {
      return this.league.programs[0].typeProgramID || ''
    }
    return ''
  }

  get leagueID() {
    return this.$route.params.id
  }

  public async mounted() {
    await Promise.all([
      this.refreshLeagues(),
      this.refreshFacilities(),
      this.refreshGames(),
      this.refreshEvents(),
      this.refreshTeams(),
    ])

    this.loading = false
  }

  get tabs(): LeagueListItem {
    if (this.league && this.league.programs) {
      return LeagueProgams2LeagueListItem(this.league.programs)
    }
    return getEmptyLeagueListItem(new LeagueListItemSeed())
  }

  get firstGameDate(): string {
    const firstGameDate = this.league.firstGameDate ?? new Date()
    return dayjs(firstGameDate).format('YYYY-MM-DD')
  }
  get gameDuration(): number {
    if (this.division) return this.division.gameLength
    return 0
  }

  range: CalendarWeek | null = null
  currentRange(r: CalendarWeek) {
    this.range = r
    this.round = r.weekNumber + 1
  }

  get calendarWeekNumber() {
    return this.round - 1
  }
}
