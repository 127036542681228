import { DivisionGame } from '@/GeneratedTypes/DivisionGame'
import { DivisionGameModified } from '@/models/Games/DivisionGameModified'

export function getEmptyDivisionGame(seed: DivisionGameSeed): DivisionGame {
  return {
    awayTeamID: 0,
    divisionID: 0,
    facilityID: 0,
    gameEnd: null,
    gameID: 0,
    gameStart: null,
    homeTeamID: 0,
    leagueID: seed.leagueID,
    roundNumber: 0,
    typeProgramID: '',
    volunteers: [],
  }
}

class DivisionGameSeed {
  leagueID = 0
  gameID = 0
}

let GAME_ID = -1
export function decrementingDGStrategy(leagueID: number) {
  const c = new DivisionGameSeed()
  c.leagueID = leagueID
  c.gameID = GAME_ID--
  return c
}

export function isEmptyDivisionGame(which: DivisionGame | DivisionGameModified): boolean {
  return which.gameID <= 0
}
